import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import ContentSectionMarkdown from "../../components/homepage/ContentSection-md"

const WinesPage = props => {
  let text =
    "If you always wanted to take part in Italian wine culture, read on! Italian wine tradition goes back a very long way and there are so many enticing wines to choose from, learn about and taste. The history of each area is unique, each terroir tells a story; the avenues of exploration are endless, the heritage and sense of Italian wine can only be discovered by clicking here…\n"
  return (
    <Layout>
      <hr style={{ background: "white" }} />
      <ContentSectionMarkdown
        title="WINE HERITAGE"
        text={text}
        align="center"
        data={props.data.wines_}
        lg={3}
        xs={12}
        sm={4}
        height="180px"
        style={{ image: "contain", height: "180px" }}

      />
    </Layout>
  )
}
export default WinesPage

export const pageQuery = graphql`
    {
        wines_: allMarkdownRemark(
            filter: { frontmatter: { layout: { eq: "wine" } } }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        subtitle
                        date
                        content
                        preparationTime
                        serving
                        category
                        region
                        tags
                        suggested_articles
                        suggested_recipes
                        suggested_wines
                        featuredImage {
                            childImageSharp {
                                fixed {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
